import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { RetryModal } from 'swp-components/lib/components/best-deal'
import Footer from 'swp-components/lib/components/footer'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Header from '@/components/header'
import Newrelic from '@/components/newrelic-agent'
import RecommendPackageDetail from '@/components/recommend-package'
import Seo from '@/components/seo'
import SurveyDetail from '@/components/survey'
import useHomePageDataLayer from '@/hooks/data-layers/use-home-page'
import NotFoundPage from '@/pages/404'
import strapi from '@/strapi'

const PageSurveyDetail = ({ serverData, location }) => {
  let [isWindowDefined, setIsWindowDefined] = useState(false)
  let [showQuestionaire, setShowQuestionaire] = useState(true)
  const [recommendPackages, setRecommendPackages] = useState([])
  let [
    questionaires,
    todayPromotions,
    contactInfo,
    headerScript,
    brandHeaders,
    productServices,
    footerHeaders,
    chatShop,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)
  const [allAnswer, setAllAnswer] = useState([])
  let { handleMessage } = useHomePageDataLayer()
  let [modalShow, setModalShow] = useState(false)
  let countRetry = 0

  useEffect(() => {
    setIsWindowDefined(true)
  }, [isWindowDefined])

  let slug = serverData.slug
  if (!slug || slug === '') {
    return <NotFoundPage />
  }
  if (questionaires == null || questionaires.length === 0) {
    return <NotFoundPage />
  }

  const submitAnswer = async allAnswer => {
    setAllAnswer(allAnswer)
    try {
      let config = {
        captcha: {
          siteKey: process.env.GATSBY_INTERNET_PACKAGE_CAPTCHA_SITE_KEY,
          action: process.env.GATSBY_INTERNET_PACKAGE_CAPTCHA_ACTION,
        },
      }
      let token = await window.grecaptcha.execute(config.captcha.siteKey, {
        action: config.captcha.action,
      })

      let url = '/tos/package-recommend'

      let dataAns = allAnswer

      let options = {
        baseURL: process.env.GATSBY_SERVICE_API_URL,
        headers: { 'Recaptcha-Response': token },
      }
      setShowQuestionaire(false)
      countRetry = countRetry + 1
      await axios.post(url, dataAns, options).then(res => {
        const { data } = res
        if (data.status === 'OK' && data.data.length > 0) {
          setRecommendPackages(data.data)
        } else {
          setModalShow(true)
        }
        window.scrollTo(0, 0)
      })
    } catch (error) {
      if (countRetry < 2) {
        setTimeout(() => {
          submitAnswer(allAnswer)
        }, 2000)
      } else {
        setModalShow(true)
        countRetry = countRetry + 1
      }
    }
  }

  let handleRetryAction = action => {
    if (action === 'tryagain') {
      setModalShow(false)
      submitAnswer(allAnswer)
    }
  }

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={questionaires.seo} script={headerScript.script} />
        <Header items={brandHeaders} services={productServices} />

        {isWindowDefined && showQuestionaire && (
          <SurveyDetail
            questionaires={questionaires}
            locale={serverData.locale}
            submitHandle={submitAnswer}
            popupWording={contactInfo}
          />
        )}

        {isWindowDefined && !showQuestionaire && (
          <RecommendPackageDetail
            packages={recommendPackages}
            todayPromotions={todayPromotions}
            contactInfo={contactInfo}
            locale={serverData.locale}
          />
        )}

        <Footer className="pb-4" items={footerHeaders} />
        <ChatShop
          content={chatShop}
          mariPlatform={ serverData.locale == 'th' ? "mari_tmh" : "truemoveh" }
          mariConfig={mariConfig}
        />
        <RetryModal
          modal={{}}
          locale={serverData.locale}
          show={modalShow}
          onAction={handleRetryAction}
        ></RetryModal>
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default PageSurveyDetail

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents(
    [
      `questionnaires/${params.slug}?_locale=${pageContext.locale}`,
      `today-s-promotions?_locale=${pageContext.locale}`,
      `questionnaire-contact-info?_locale=${pageContext.locale}`,
      `header-script?_locale=${pageContext.locale}`,
      `brand-headers?_locale=${pageContext.locale}`,
      `product-services?_locale=${pageContext.locale}`,
      `footer-headers?_locale=${pageContext.locale}`,
      `chat-and-shop?_locale=${pageContext.locale}`,
      'mari-chat-and-shop-config',
    ]
    // pageContext.locale
  )

  return {
    props: { contents, slug: params.slug, locale: pageContext.locale },
  }
}
