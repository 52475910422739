import { Col, Row } from 'react-bootstrap'
import {
  BackgroundCircle,
  BackgroundContact,
  PostpaidCard,
} from 'swp-components/lib/components/best-deal'
import { Container } from 'swp-components/lib/components/bootstrap'
import { Button } from 'swp-components/lib/components/common'
import { Heading } from 'swp-components/lib/components/section'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import SectionHeading from '@/components/section-heading'
import useTruemovehPostpaidPageDataLayer from '@/hooks/data-layers/truemoveh/use-truemoveh-postpaid-page'
import useMobilePackage from '@/hooks/use-mobile-package'
import generateSliderOptions from '@/util/generate-slider-options'

import Slider from '../slider'
import style from './style'

const RecommendPackageDetail = ({
  packages,
  todayPromotions,
  contactInfo,
  locale = 'th',
  ...props
}) => {
  let { handleMessage } = useTruemovehPostpaidPageDataLayer()
  let mobilePostpaidPackages = useMobilePackage(
    'tmh-recommend-package-postpaid',
    {
      recPackages: packages ? packages : [],
      locale,
    }
  )

  let todayPromotionsPackages = useMobilePackage('tmh-today-package-postpaid', {
    recPackages: todayPromotions ? todayPromotions : [],
    locale,
  })

  let reccomendMobilePostpaidPackages = mobilePostpaidPackages[0]
    ? [mobilePostpaidPackages[0]]
    : []
  let altMobilePostpaidPackages = []
  for (let index = 1; index < mobilePostpaidPackages.length; index++) {
    if (mobilePostpaidPackages[index]) {
      altMobilePostpaidPackages.push(mobilePostpaidPackages[index])
    }
  }
  let sliderOptions = generateSliderOptions(340)

  return (
    <DataLayerProvider handler={handleMessage}>
      <BackgroundCircle>
        <Container className="pt-3">
          <SectionHeading className="mt-5 mb-2" level="1">
            {contactInfo.resultPage.name}
          </SectionHeading>
          <Heading className="mt-5" variant={'default'}>
            {contactInfo.recommendedPackages.name}
          </Heading>
          <SectionHeading className="mt-3 mb-3" variant="underline" level="2">
            {contactInfo.recommendedPackages.detail}
          </SectionHeading>
          <Row className="justify-content-center">
            <Col md={8} lg={8}>
              {reccomendMobilePostpaidPackages.map((pack, index) => (
                <PostpaidCard
                  key={index}
                  className="mx-auto"
                  pack={pack}
                  style={{ width: '250px' }}
                />
              ))}
            </Col>
          </Row>
        </Container>

        <Container className="pt-3">
          <Heading className="mt-5" variant={'default'}>
            {contactInfo.packagesYouMayLike.name}
          </Heading>
          <SectionHeading
            className="mt-3 mb-3"
            variant="underline"
            level="2"
          ></SectionHeading>
          <Row className="justify-content-center">
            <Col md={10} lg={10} className="text-center">
              {altMobilePostpaidPackages.length > 0 && (
                <div css={style.sliderWrapper}>
                  <Slider options={sliderOptions}>
                    {altMobilePostpaidPackages.map((pack, index) => (
                      <div key={index} css={style.box}>
                        <PostpaidCard pack={pack} />
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </Col>
          </Row>

          <Heading className="mt-5" variant={'default'}>
            {contactInfo.highlightPackage.name}
          </Heading>
          <SectionHeading className="mt-3 mb-3" variant="underline" level="2">
            {contactInfo.highlightPackage.detail}
          </SectionHeading>
          <Row className="justify-content-center">
            <Col md={10} lg={10} className="text-center">
              {todayPromotionsPackages.length > 0 && (
                <div css={style.sliderWrapper}>
                  <Slider options={sliderOptions}>
                    {todayPromotionsPackages.map((pack, index) => (
                      <div key={index} css={style.box}>
                        <PostpaidCard pack={pack} />
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col md={8} lg={8} className="text-center">
              <Button link={contactInfo.linkMorePackage}>
                {contactInfo.linkMorePackageText}
              </Button>
            </Col>
          </Row>
        </Container>
        <BackgroundContact
          className="mt-2 mt-md-5"
          contact={contactInfo.contactStaff}
        />
      </BackgroundCircle>
    </DataLayerProvider>
  )
}

export default RecommendPackageDetail
