import { flatten, groupBy } from 'lodash-es'
import { useIntl } from 'react-intl'

const useMobilePackage = (type, data) => {
  let intl = useIntl()
  if (type === 'all') {
    let {
      allPostpaidPackages,
      allPrepaidPackages,
      allExtraPackages,
      viewAllManagement,
    } = data

    allPostpaidPackages = allPostpaidPackages.filter(a => {return a.tmhPostpaidPackage != null})
    allPrepaidPackages = allPrepaidPackages.filter(a => {return a.tmhPrepaidPackage != null})
    allExtraPackages = allExtraPackages.filter(a => {return a.package != null})

    allPostpaidPackages.filter(a => a.id != null && a.tmhPostpaidPackage.id != null)
    allPrepaidPackages.filter(a => a.id != null && a.tmhPrepaidPackage.id != null)
    allExtraPackages.filter(a => a.id != null && a.package.id != null)

    allPostpaidPackages = flatten(
      allPostpaidPackages
        .map(pack => ({
          ...pack.tmhPostpaidPackage,
          id: `${pack.id}:${pack.tmhPostpaidPackage.id}`,
        }))
        .map(pack =>
          pack.packagePriceplans
            .filter(plan => plan.show)
            .map(plan => ({
              ...pack,
              id: `${pack.id}:${plan.id}`,
              priceplan: plan,
            }))
        )
    )

    allPrepaidPackages = flatten(
      allPrepaidPackages
        .map(pack => ({
          ...pack.tmhPrepaidPackage,
          id: `${pack.id}:${pack.tmhPrepaidPackage.id}`,
        }))
        .map(pack =>
          pack.packagePriceplans
            .filter(plan => plan.show)
            .map(plan => ({
              ...pack,
              id: `${pack.id}:${plan.id}`,
              priceplan: plan,
            }))
        )
    )

    allExtraPackages = flatten(
      allExtraPackages
        .map(pack => ({
          ...pack.package,
          id: `${pack.id}:${pack.package.id}`,
        }))
        .map(pack =>
          pack.priceplan
            .filter(plan => plan.show)
            .map(plan => ({
              ...pack,
              id: `${pack.id}:${plan.id}`,
              priceplan: plan,
            }))
        )
    )

    return [
      {
        type: 'postpaid',
        title: intl.formatMessage({ id: 'postpaidPackages' }),
        packages: allPostpaidPackages,
        viewAll: viewAllManagement.tmhPostpaidViewAll,
      },
      {
        type: 'prepaid',
        title: intl.formatMessage({ id: 'prepaidPackages' }),
        packages: allPrepaidPackages,
        viewAll: viewAllManagement.tmhPrepaidViewAll,
      },
      {
        type: 'extra',
        title: intl.formatMessage({ id: 'extraPackages' }),
        packages: allExtraPackages,
        viewAll: viewAllManagement.extraPackageViewAll,
      },
    ]
  }

  if (type === 'tmh-highlight-postpaid') {
    let { postpaidPackages } = data
    postpaidPackages = flatten(
      postpaidPackages.map(pack =>
        pack.packagePriceplans
          .filter(plan => plan.show)
          .map(plan => ({
            ...pack,
            id: `${pack.id}:${plan.id}`,
            priceplan: plan,
          }))
      )
    )

    return [
      {
        type: 'postpaid',
        packages: postpaidPackages,
      },
    ]
  }

  if (type === 'tmh-highlight-prepaid') {
    let { prepaidPackages } = data

    prepaidPackages = flatten(
      prepaidPackages.map(pack =>
        pack.packagePriceplans
          .filter(plan => plan.show)
          .map(plan => ({
            ...pack,
            id: `${pack.id}:${plan.id}`,
            priceplan: plan,
          }))
      )
    )

    return [
      {
        type: 'prepaid',
        packages: prepaidPackages,
      },
    ]
  }

  if (type === 'tmh-extra') {
    let { extraPackages, category } = data

    extraPackages = flatten(
      extraPackages
        .filter(pack => pack.tmhCategory === category)
        .map(pack =>
          pack.priceplan.map(plan => ({
            ...pack,
            id: `${pack.id}:${plan.id}`,
            priceplan: plan,
          }))
        )
    )

    return [
      {
        type: 'extra',
        packages: extraPackages,
      },
    ]
  }

  if (type === 'tmh-package-type-postpaid') {
    let { postpaidPackages } = data

    return postpaidPackages.map(pack => ({
      id: pack.id,
      title: pack.name,
      subtitle: pack.detail,
      packages: [
        {
          type: 'postpaid',
          packages: pack.packagePriceplans.map(plan => ({
            ...pack,
            id: `${pack.id}:${plan.id}`,
            priceplan: plan,
          })),
        },
      ],
    }))
  }

  if (type === 'tmh-package-type-prepaid') {
    let { prepaidPackages } = data

    prepaidPackages = flatten(
      prepaidPackages.map(pack =>
        pack.packagePriceplans.map(plan => ({
          ...pack,
          id: `${pack.id}:${plan.id}`,
          priceplan: plan,
        }))
      )
    )

    return [
      {
        type: 'prepaid',
        packages: prepaidPackages,
      },
    ]
  }

  if (type === 'mnp-postpaid') {
    let { mnpPostpaidPackages } = data

    mnpPostpaidPackages = flatten(
      mnpPostpaidPackages
        .map(pack => ({
          ...pack.tmhPostpaidPackage,
          id: `${pack.id}:${pack.tmhPostpaidPackage.id}`,
        }))
        .map(pack =>
          pack.packagePriceplans.map(plan => ({
            ...pack,
            id: `${pack.id}:${plan.id}`,
            priceplan: plan,
          }))
        )
    )

    let groups = groupBy(mnpPostpaidPackages, pack => pack.name)

    return Object.entries(groups)
      .sort((a, b) => a[1][0].position - b[1][0].position)
      .map(([title, packages]) => ({
        type: 'postpaid',
        title,
        packages,
      }))
  }

  if (type === 'mnp-prepaid') {
    let { mnpPrepaidPackages } = data

    mnpPrepaidPackages = flatten(
      mnpPrepaidPackages
        .map(pack => ({
          ...pack.tmhPrepaidPackage,
          id: `${pack.id}:${pack.tmhPrepaidPackage.id}`,
        }))
        .map(pack =>
          pack.packagePriceplans.map(plan => ({
            ...pack,
            id: `${pack.id}:${plan.id}`,
            priceplan: plan,
          }))
        )
    )

    let groups = groupBy(
      mnpPrepaidPackages,
      pack => pack.packageOption.tmhPrepaidPackageType.name
    )

    return Object.entries(groups)
      .sort(
        (a, b) =>
          a[1][0].packageOption.tmhPrepaidPackageType.position -
          b[1][0].packageOption.tmhPrepaidPackageType.position
      )
      .map(([title, packages]) => ({
        type: 'prepaid',
        title,
        packages,
      }))
  }

  if (type === 'tmh-recommend-package-postpaid') {
    let { recPackages, locale } = data
    return recPackages.map(pack => ({
      id: pack.product_id ? pack.product_id : '0',
      name: pack.name[locale] ? pack.name[locale] : '',
      label: pack.title ? pack.title[locale] : '',
      priceplan: {
        sellingPrice: pack.rc ? pack.rc : '0',
        net5G4GSpecial:
          pack.net[locale].split(' ')[0].indexOf('limit') !== -1
            ? ' '
            : pack.net[locale].split(' ')[0],
        net5G4GUnit: pack.net[locale].split(' ')[1]
          ? pack.net[locale].split(' ')[1]
          : pack.net[locale].split(' ')[0],
        talkAllSpecial: pack.tel[locale].split(' ')[0],
        talkAllUnit: pack.tel[locale].split(' ')[1]
          ? pack.tel[locale].split(' ')[1]
          : ' ',
        buttonName2: locale === 'th' ? 'ซื้อเลย' : 'Buy Now',
        link: {
          url: pack.url,
          newTab: true,
        },
      },
      packageOption: {},
    }))
  }

  if (type === 'tmh-today-package-postpaid') {
    let { recPackages, locale } = data
    return recPackages.map(pack => ({
      id: pack.id ? pack.id : '0',
      name: pack.packageName ? pack.packageName : '',
      label: pack.packageLabel ? pack.packageLabel : '',
      priceplan: {
        sellingPrice: pack.pricePlan ? pack.pricePlan : '0',
        net5G4GSpecial:
          pack.net.split(' ')[0].indexOf('limit') !== -1
            ? ' '
            : pack.net.split(' ')[0],
        net5G4GUnit:
          pack.net.split(' ')[0].indexOf('limit') !== -1 ? pack.net : 'GB',
        talkAllSpecial: pack.call.split(' ')[0],
        talkAllUnit: pack.call.split(' ')[1] ? pack.call.split(' ')[1] : 'mins',
        buttonName2: locale === 'th' ? 'ซื้อเลย' : 'Buy Now',
        link: {
          url: pack.url ? pack.url.url : '',
          newTab: pack.url ? pack.url.newTab : true,
        },
      },
      packageOption: {},
    }))
  }
}

export default useMobilePackage
