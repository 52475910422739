import { css } from '@emotion/react'

const style = {
  backgroundContainer: css`
    position: relative;
    width: 100%;
  `,
}

export default style
