import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import {
  Background,
  Modal,
  Question,
  Stepper,
} from 'swp-components/lib/components/best-deal'
import { Container } from 'swp-components/lib/components/bootstrap'
import { Button } from 'swp-components/lib/components/common'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import useNewsTipsDataLayer from '@/hooks/data-layers/news-tips/use-news-tips-page'

import style from './style'

const SurveyDetail = ({
  questionaires,
  locale = 'th',
  submitHandle,
  popupWording,
  ...props
}) => {
  let { handleMessage } = useNewsTipsDataLayer()
  let [currentQuestion, setCurrentQuestion] = useState(1)
  let [currentQuestionForIndexArray, setCurrentQuestionForIndexArray] =
    useState(0)
  let [question, setQuestion] = useState(
    questionaires.branches[0].questions[0].question
  )
  let [currentAnswer, setCurrentAnswer] = useState('')
  let [allAnswer, setAllAnswer] = useState([])
  let [disableNextBtn, setDisableNextBtn] = useState(true)
  let [modalShow, setModalShow] = useState(false)
  let modalContent = {
    name: popupWording.confirmDialog
      ? popupWording.confirmDialog.name
      : 'เรากำลังหาแพ็กเกจรายเดือนที่เหมาะกับคุณ',
    termCondition: popupWording.confirmDialog
      ? popupWording.confirmDialog.detail
      : 'กรุณากด “ยืนยัน” เพื่อส่งคำตอบ หรือ “แก้ไข” เพื่อตรวจสอบอีกครั้ง',
    note: popupWording.confirmDialog
      ? popupWording.confirmDialog.subDetail
      : 'เว็บไซต์นี้ได้รับการปกป้องโดย reCAPTCHA และเป็นไปตาม นโยบายความเป็นส่วนตัว และ ข้อกำหนดในการให้บริการ ของ Google',
  }
  let [multipleChoiceBeforeNothing, setMultipleChoiceBeforeNothing] = useState(
    []
  )
  let [trigger, setTrigger] = useState(false)

  let stepDetailsArray = []
  questionaires.branches[0].questions.forEach((element, index) => {
    stepDetailsArray.push({ id: index + 1 })
  })

  questionaires.branches[0].questions[0].question.questionRemark = ''

  const clickNextBtn = () => {
    gotoTopPage()
    if (currentQuestionForIndexArray === stepDetailsArray.length - 1) {
      setModalShow(true)
    } else {
      setCurrentQuestion(currentQuestion + 1)
      setCurrentQuestionForIndexArray(currentQuestionForIndexArray + 1)
      setQuestion(
        questionaires.branches[0].questions[currentQuestionForIndexArray + 1]
          .question
      )
      if (allAnswer[currentQuestionForIndexArray + 1]) {
        setCurrentAnswer(allAnswer[currentQuestionForIndexArray + 1])
        setDisableNextBtn(false)
      } else {
        if (
          questionaires.branches[0].questions[currentQuestionForIndexArray + 1]
            .question.multipleChoice === true
        ) {
          setCurrentAnswer([])
        } else {
          setCurrentAnswer('')
        }

        setDisableNextBtn(true)
      }
    }
  }

  const clickBackBtn = () => {
    gotoTopPage()
    setCurrentQuestion(currentQuestion - 1)
    setCurrentQuestionForIndexArray(currentQuestionForIndexArray - 1)
    setQuestion(
      questionaires.branches[0].questions[currentQuestionForIndexArray - 1]
        .question
    )
    if (allAnswer[currentQuestionForIndexArray - 1]) {
      setCurrentAnswer(allAnswer[currentQuestionForIndexArray - 1])
      setDisableNextBtn(false)
    } else {
      setDisableNextBtn(true)
      if (
        questionaires.branches[0].questions[currentQuestionForIndexArray - 1]
          .question.multipleChoice === true
      ) {
        setCurrentAnswer([])
      } else {
        setCurrentAnswer('')
      }
    }
  }

  const clickSelect = (value, isCheck) => {
    if (
      questionaires.branches[0].questions[currentQuestionForIndexArray].question
        .multipleChoice === true
    ) {
      if (isCheck === true) {
        let index = currentAnswer.indexOf(value)
        if (index === -1) {
          if (value === 'Nothing') {
            setMultipleChoiceBeforeNothing(currentAnswer)
            currentAnswer = [value]
          } else {
            if (typeof currentAnswer === 'string') {
              currentAnswer = []
            }
            currentAnswer[currentAnswer.length] = value
          }
          setCurrentAnswer(currentAnswer)
        }
      } else {
        let index = currentAnswer.indexOf(value)
        if (index !== -1) {
          if (value === 'Nothing') {
            currentAnswer = multipleChoiceBeforeNothing
            setMultipleChoiceBeforeNothing([])
          } else {
            currentAnswer.splice(index, 1)
          }
          setCurrentAnswer(currentAnswer)
        }
      }
      allAnswer[currentQuestionForIndexArray] = currentAnswer
      if (
        currentAnswer.length > 0 &&
        currentAnswer.length <=
          questionaires.branches[0].questions[currentQuestionForIndexArray]
            .question.multipleChoiceLimit
      ) {
        setDisableNextBtn(false)
      } else {
        setDisableNextBtn(true)
      }
    } else {
      setCurrentAnswer(value)
      allAnswer[currentQuestionForIndexArray] = value
      setDisableNextBtn(false)
    }

    let conditions =
      questionaires.branches[0].questions[currentQuestionForIndexArray]
        .conditions
    if (conditions.length > 0) {
      conditions.forEach(cond => {
        if (cond.answer === value) {
          if (cond.url) {
            if (cond.url.newTab) {
              window.open(cond.url.url)
            } else {
              window.location.replace(cond.url.url)
            }
          }
          setDisableNextBtn(true)
        }
      })
    }
    setAllAnswer(allAnswer)
    setTrigger(!trigger)
  }

  let handleAction = action => {
    if (action === 'close-modal') {
      setModalShow(false)
    }
    if (action === 'confirm') {
      let answerResult = {
        limit: 3,
        answers: [],
      }

      questionaires.branches[0].questions.forEach((question, index) => {
        if (!question.question.multipleChoice) {
          answerResult.answers[index] = {
            answer: [allAnswer[index]],
            direction: question.question.direction,
          }
        } else {
          answerResult.answers[index] = {
            answer: allAnswer[index],
            direction: question.question.direction,
          }
        }
      })
      submitHandle(answerResult)
    }
  }

  const gotoTopPage = () => {
    setTimeout(function () {
      window.scrollTo(0, 0)
    }, 300)
  }

  return (
    <DataLayerProvider handler={handleMessage}>
      <div css={style.backgroundContainer}>
        <Background style={{}}>
          <Container className="pt-5 pb-5">
            <Row className="justify-content-center">
              <Col lg={8}>
                <Stepper
                  className="mb-2 mb-sm-4"
                  step={currentQuestion}
                  stepper={{
                    header: questionaires.name,
                    subheader: questionaires.description,
                    stepDetails: stepDetailsArray,
                  }}
                ></Stepper>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={8}>
                <Question
                  question={question}
                  numberQuestion={currentQuestion}
                  handleChange={clickSelect}
                  selectedInput={currentAnswer}
                ></Question>
              </Col>
            </Row>
            <Row
              className="justify-content-center"
              style={{ textAlign: 'right', fontFamily: 'var(--text-normal)' }}
            >
              <Col lg={8} className="pt-4">
                {currentQuestion > 1 ? (
                  <Button
                    variant="outlined"
                    style={{ marginRight: '15px' }}
                    onClick={clickBackBtn}
                  >
                    <FormattedMessage
                      id="survey.backButtonText"
                      defaultMessage="Back"
                    ></FormattedMessage>
                  </Button>
                ) : (
                  ''
                )}
                <Button onClick={clickNextBtn} disabled={disableNextBtn}>
                  <FormattedMessage
                    id="survey.nextButtonText"
                    defaultMessage="Next"
                  ></FormattedMessage>
                </Button>
              </Col>
            </Row>
          </Container>
        </Background>
        <Modal
          modal={modalContent}
          show={modalShow}
          onAction={handleAction}
        ></Modal>
      </div>
    </DataLayerProvider>
  )
}

export default SurveyDetail
