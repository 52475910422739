import { css } from '@emotion/react'

const style = {
  subTitle: css`
    text-align: center;
    color: 000;
  `,

  sliderWrapper: css`
    position: relative;
    margin: 0 -0.5rem;
    padding: 0 0.5rem;

    @media (min-width: 768px) {
      margin: 0 -0.75rem;
      padding: 0 0.75rem;
    }
  `,

  box: css`
    padding: 2px 0.25rem;
    width: 300px !important;
    @media (min-width: 768px) {
      width: 282px !important;
      padding: 2px 0.5rem;
    }
  `,
}

export default style
